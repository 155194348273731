import {
  Chart,
  // ChartConfiguration,
  // CategoryScale,
  TimeScale,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Filler,
  Tooltip,
  ChartDataset,
  TooltipItem,
  ScriptableContext,
} from 'chart.js'

import 'chartjs-adapter-moment'
import { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'
import { HistoricalBiomarkerResult } from '../../lib/validators'

import { getResultStatus, ResultStatusTypes } from '../../lib/admin'

import { months } from '../../lib/utils'

Chart.register(
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  // CategoryScale,
  TimeScale,
  Filler,
  Tooltip
)

interface ChartSize {
  height?: number
  width?: number
}

interface BasicBiomarkerLineChartProps {
  data: HistoricalBiomarkerResult[] | undefined
  size?: ChartSize
}

interface ChartData {
  values: number[]
  refHigh: (number | null)[]
  refLow: (number | null)[]
  labels: string[]
  min?: number
  max?: number
  padding: number
  unit?: string
}

const rangeStyling = {
  backgroundColor: 'rgba(254, 152, 1, 0.2)',
  // fill: { value: Math.max(...chartDataHigh) + 5 },
  fill: { value: 20 },
  borderWidth: 0.5,
  borderDash: [10, 5],
  cubicInterpolationMode: 'monotone',
  pointStyle: false,
}

export function BiomarkerLineChart({
  data,
  size,
}: BasicBiomarkerLineChartProps) {
  const [historicalBiomarkerData, setHistoricalBiomarkerData] = useState<
    HistoricalBiomarkerResult[] | undefined
  >(data)
  const [chartData, setChartData] = useState<ChartData>()

  useEffect(() => {
    setHistoricalBiomarkerData(data)
  }, [data])

  useEffect(() => {
    const tmpChartData: ChartData = {
      labels: [],
      values: [],
      refHigh: [],
      refLow: [],
      padding: 5,
    }

    let tmpUnit: string | undefined = ''
    let tmpLowestValue: number = Infinity
    let tmpHighestValue: number = 0
    historicalBiomarkerData?.map((d) => {
      tmpUnit = d.unit || undefined
      tmpChartData.values.push(d.value)
      tmpChartData.labels.push(d.date.toISOString())
      // if (d.refIntervalHigh) {
      tmpChartData.refHigh.push(d.refIntervalHigh)
      // }
      // if (d.refIntervalLow) {
      tmpChartData.refLow.push(d.refIntervalLow)
      // }
      if (d.refIntervalHigh !== null && d.refIntervalHigh > tmpHighestValue) {
        tmpHighestValue = d.refIntervalHigh
      }
      if (d.refIntervalLow !== null && d.refIntervalLow > tmpLowestValue) {
        tmpLowestValue = d.refIntervalLow
      }
    })
    tmpChartData.min = Math.min(tmpLowestValue)
    tmpChartData.max = Math.max(tmpHighestValue)
    tmpChartData.padding = tmpChartData.max - tmpChartData.min * 1.5 || 1
    tmpChartData.unit = tmpUnit
    setChartData(tmpChartData)
  }, [historicalBiomarkerData])

  const getDataSets = (): ChartDataset<'line'>[] => {
    return [
      {
        data: chartData!.values,
        borderColor: 'rgba(0,0,0,0.5)',
        cubicInterpolationMode: 'monotone',
        pointRadius: 5,
        pointHoverRadius: 10,
        // pointBorderColor: 'rgb(16, 185, 129)',
        pointBorderColor: (item: ScriptableContext<'line'>): string => {
          const low = chartData?.refLow[item.dataIndex]
          const high = chartData?.refHigh[item.dataIndex]
          const result = getResultStatus(
            item.parsed.y.toString(),
            low?.toString(),
            high?.toString()
          )
          if (result === ResultStatusTypes.abnormal) {
            return '#FE9801'
          }
          return 'rgb(16, 185, 129)'
        },
        pointBackgroundColor: (item: ScriptableContext<'line'>): string => {
          const low = chartData?.refLow[item.dataIndex]
          const high = chartData?.refHigh[item.dataIndex]
          const result = getResultStatus(
            item.parsed.y.toString(),
            low?.toString(),
            high?.toString()
          )
          if (result === ResultStatusTypes.abnormal) {
            return '#FE9801'
          }
          return 'rgb(16, 185, 129)'
        },
        label: 'value',
      },
      {
        ...rangeStyling,
        data: chartData!.refLow,
        fill: 'origin',
        cubicInterpolationMode: 'monotone',
        pointStyle: false,
      },
      {
        ...rangeStyling,
        data: chartData!.refHigh,
        fill: 'end',
        cubicInterpolationMode: 'monotone',
        pointStyle: false,
      },
    ]
  }
  // const getMinimumXValue = (): number => {
  //   const d = new Date(Date.parse(chartData!.labels[0]))
  //   d.setDate(1)
  //   return Date.parse(d.toISOString())
  // }

  return (
    <div className="min-h-56">
      {chartData && chartData.values.length > 0 && (
        <Line
          width={size?.width}
          height={size?.height}
          data={{
            labels: chartData.labels,
            datasets: getDataSets(),
          }}
          options={{
            maintainAspectRatio: false,
            scales: {
              x: {
                alignToPixels: true,
                type: 'time',
                time: {
                  unit: 'month',
                },
                // suggestedMin: getMinimumXValue(),
                ticks: {
                  callback: function (val, index) {
                    // Hide every 2nd tick label
                    if (typeof val === 'string') {
                      return ''
                    }
                    const d = new Date(Date.parse(this.getLabelForValue(val)))
                    return index % 2 === 0
                      ? `${months[d.getMonth()]} '${d.getFullYear().toString().substring(2, 4)}`
                      : ''
                  },
                },
              },
              y: {
                beginAtZero: true,
                alignToPixels: true,
                // ticks: {
                //   color: '#ff0000',
                // },
                grid: {
                  color: 'rgba(0,0,0,0.05)',
                },
              },
            },

            interaction: {
              intersect: false,
              mode: 'index',
            },
            plugins: {
              tooltip: {
                callbacks: {
                  label: (item: TooltipItem<'line'>): string => {
                    // console.log('yaxis', item.dataset.label)
                    return item.dataset.label
                      ? ` ${item.formattedValue} ${chartData.unit ? chartData.unit : ''} `
                      : ''
                  },
                  footer: (items: TooltipItem<'line'>[]): string => {
                    // console.log('yaxis', item.dataset.label)
                    const ranges: string[] = []
                    let hasLow = false
                    let hasHigh = false
                    items.map((item) => {
                      if (item.datasetIndex > 0) {
                        ranges.push(
                          `${item.formattedValue} ${chartData.unit ? chartData.unit : ''} `
                        )
                      }
                      if (item.datasetIndex === 1) {
                        hasLow = true
                      }
                      if (item.datasetIndex === 2) {
                        hasHigh = true
                      }
                    })
                    const baseString = 'Normal Range: \n'
                    if (hasLow && !hasHigh) {
                      return `${baseString} > ${ranges.join(' - ')}`
                    }
                    if (!hasLow && hasHigh) {
                      return `${baseString} < ${ranges.join(' - ')}`
                    }
                    return ranges.length > 0
                      ? `${baseString}${ranges.join(' - ')}`
                      : ''
                  },
                },
              },
            },
          }}
        ></Line>
      )}
      {/* {chartData && chartData.values.length === 0 && (
        <ResultInfoSection>No historical data</ResultInfoSection>
      )} */}
      {!chartData && <span>Loading...</span>}
    </div>
  )
}
