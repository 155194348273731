import { useEffect, useState } from 'react'
import { z } from 'zod'
import { authFetch } from '../providers/AuthProvider'
import { HL7Triage, HL7TriageSchema } from './validators'

export const HL7TriageFilterSchema = z.object({
  status: z.string().optional(),
  search: z.string().optional(),
  uuids: z.array(z.string().uuid()).optional(),
})

export const HL7TriageSortOptionsSchema = z.object({
  field: z.string(),
  order: z.enum(['ASC', 'DESC']),
})

export const HL7TriageRequestSchema = z.object({
  filter: HL7TriageFilterSchema.optional(),
  sort: HL7TriageSortOptionsSchema,
  limit: z.number().int().positive().optional(),
  includeContent: z.boolean().default(false).optional(),
})

export type HL7TriageRequest = z.infer<typeof HL7TriageRequestSchema>

export const fetchHL7Triage = async (
  request: z.infer<typeof HL7TriageRequestSchema>
) => {
  // Validate the request before sending
  HL7TriageRequestSchema.parse(request)

  const response = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `
        query adminHL7Triage($filter: HL7TriageFilter, $sort: HL7TriageSortOptions, $limit: Int, $includeContent: Boolean) {
          adminHL7Triage(filter: $filter, sort: $sort, limit: $limit, includeContent: $includeContent) {
            uuid
            sourceUuid
            hl7Key
            jsonKey
            reference
            provider
            conversationKey
            status
            createdAt
            updatedAt
            hl7Content
            jsonContent
          }
        }
      `,
      variables: request,
    }),
  })

  if (response.status !== 200) {
    throw new Error(`Failed to fetch HL7 triage: ${response.statusText}`)
  }

  const result = response.data
  if (result.errors) {
    throw new Error(`GraphQL errors: ${JSON.stringify(result.errors)}`)
  }

  const data = z.array(HL7TriageSchema).parse(result.data.adminHL7Triage)

  return data
}

export function useHL7Triage(request: HL7TriageRequest) {
  const [items, setItems] = useState<HL7Triage[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      setError(null)

      try {
        const data = await fetchHL7Triage(request)
        setItems(data)
      } catch (err) {
        setError('Failed to fetch HL7 triage data.')
        console.error(err)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [request])

  return { items, loading, error }
}
