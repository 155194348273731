import { useEffect, useState, useRef } from 'react'

export function useOnceCall(cb: () => void, condition = true) {
  const isCalledRef = useRef(false)

  useEffect(() => {
    if (condition && !isCalledRef.current) {
      isCalledRef.current = true
      cb()
    }
  }, [cb, condition])
}

export const useCurrentTheme = () => {
  const [theme, setTheme] = useState(
    document.documentElement.classList.contains('dark') ? 'dark' : 'light'
  )

  useEffect(() => {
    const htmlElement = document.documentElement

    // Define the observer callback
    const observer = new MutationObserver(() => {
      setTheme(htmlElement.classList.contains('dark') ? 'dark' : 'light')
    })

    // Start observing the <html> element for class changes
    observer.observe(htmlElement, {
      attributes: true, // Monitor attribute changes
      attributeFilter: ['class'], // Only observe changes to the 'class' attribute
    })

    // Cleanup observer on hook unmount
    return () => observer.disconnect()
  }, [])

  return theme
}
