// Initialization is managed through a singleton Promise to avoid race conditions or redundant setup
// when multiple MonacoEditor components are rendered simultaneously.
// The default loader config is used which gets the Monaco editor from a CDN.
// This keeps our bundle minimal.
// https://github.com/suren-atoyan/monaco-react?tab=readme-ov-file

import React, { lazy, Suspense, useEffect, useState } from 'react'

const Editor = lazy(() => import('@monaco-editor/react'))

// Singleton promise for initialization
let monacoInitializationPromise: Promise<void> | null = null

// Monaco initialization logic with dynamic worker setup
async function initializeMonacoEditor() {
  // Return existing Promise if already initializing
  if (monacoInitializationPromise) return monacoInitializationPromise

  monacoInitializationPromise = (async () => {
    const { loader } = await import('@monaco-editor/react')
    await loader.init()
    console.log('Monaco Editor initialized')
  })()

  return monacoInitializationPromise
}

// Wrapper around MonacoEditor to handle worker initialization and lazy loading
export default function MonacoEditor(
  props: React.ComponentProps<typeof Editor>
) {
  const [monacoReady, setMonacoReady] = useState(false)

  // Initialize Monaco Editor once
  useEffect(() => {
    initializeMonacoEditor().then(() => setMonacoReady(true))
  }, [])

  if (!monacoReady) {
    return <div>Loading Monaco Editor...</div>
  }

  return (
    <Suspense fallback={<div>Loading Editor Component...</div>}>
      <Editor {...props} />
    </Suspense>
  )
}
