import { Fragment, useEffect, useState } from 'react'
import { ThemeInterface } from '../../../../../lib/interfaces'
import { Modal } from '../../../../layout/Modal'
import { TestWithBiomarkersSelected } from '../../../../../lib/validators'
import { formatMoney } from '../../../../../lib/utils'

interface TestInfoModalProps {
  show: boolean
  test?: TestWithBiomarkersSelected
  theme?: ThemeInterface
  setShow: (openState: boolean) => void
}

export function TestInfoModal({
  show,
  setShow,
  theme,
  test,
}: TestInfoModalProps) {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(show)
  }, [show, test])

  return (
    <Modal
      show={open}
      options={{
        mainActions: [
          {
            label: 'Dismiss',
            handler: () => setShow(false),
          },
        ],
        title: test && test.name ? test.name : '',
        content: (
          <div>
            {test && (
              <Fragment>
                <div className="mb-4 mt-2 text-lg">{test.description}</div>
                {test.biomarkers && test.biomarkers.length > 0 && (
                  <Fragment>
                    <div className="mt-2 text-lg">Included biomarkers:</div>
                    <ul>
                      {test.biomarkers?.map((biomarker) => (
                        <li className="px-2 my-1 mr-1 inline-block border-2 border-dark-gray-lighterer cursor-default text-md rounded-md text-dark-gray-light">
                          {biomarker.name}
                        </li>
                      ))}
                    </ul>
                  </Fragment>
                )}
                <div className="mt-2 font-bold">
                  Price:
                  {(test && formatMoney(test.price / 100, 'aud')) ||
                    'no selected test'}
                </div>
              </Fragment>
            )}
          </div>
        ),
      }}
      onClose={() => setShow(false)}
      theme={theme || undefined}
      size="lg"
    ></Modal>
  )
}
