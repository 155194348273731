import { Fragment, useEffect, useState } from 'react'
import { ResultReferral } from '../../../lib/validators'
import { ResultsTabProps } from '../../../lib/interfaces'

async function fetchBlobs(urls: string[]): Promise<Blob[]> {
  // Use Promise.all to fetch all URLs in parallel
  const blobPromises = urls.map(async (url) => {
    const response = await fetch(url)

    if (!response.ok) {
      throw new Error(`Failed to fetch ${url}: ${response.statusText}`)
    }

    return response.blob() // Convert the response to a Blob
  })

  // Wait for all blob promises to resolve
  return Promise.all(blobPromises)
}

export const ResultTabDocument: React.FC<ResultsTabProps> = ({
  resultReferral,
  files,
}) => {
  // export function ResultTabOverview({ referralResult }: ResultsTabProps) {
  const [result, setResult] = useState<ResultReferral>()
  const [resultFileUrls, setResultFileUrls] = useState<string[]>([])
  const [resultBlobs, setResultBlobs] = useState<Blob[]>([])

  useEffect(() => {
    setResult(resultReferral)
  }, [resultReferral])
  useEffect(() => {
    if (files) setResultFileUrls(files)
  }, [files])

  useEffect(() => {}, [resultFileUrls])

  useEffect(() => {
    if (files && files.length > 0) {
      fetchBlobs(files).then((blobsData) => {
        setResultBlobs(blobsData)
      })
    }
  }, [files])

  return (
    <Fragment>
      {!result && <div>Loading...</div>}
      {result && (
        <Fragment>
          {resultBlobs &&
            resultBlobs.map((blobbie, i) => (
              <embed
                key={`result-blob-${i}`}
                src={URL.createObjectURL(blobbie)}
                type="application/pdf"
                width="100%"
                height="1000px"
                className="mb-6"
              />
            ))}
        </Fragment>
      )}
    </Fragment>
  )
}
