import { Fragment, useEffect, useState } from 'react'
import { useOrganisationContext } from '../../../context/OrganisationContext'

import OrgPage from '../../../OrgPage'
import PageSection from '../../../layout/PageSection'
import { PageHeading } from '../../../layout/PageHeading'
import Clipboard from '../../../../../public/img/clipboard.svg'

import { useUser } from '../../../../lib/store'
import { Referral } from '../../../../lib/validators'
import {
  BasicTableGroupProps,
  BasicTableProps,
} from '../../../layout/BasicTable'
import {
  downloadFile,
  getNiceDate,
  months,
  setDocumentTitle,
} from '../../../../lib/utils'
import { EmptyState } from '../../../layout/EmptyState'

import PageLoader from '../../../layout/PageLoader'
import { getTestsData } from '../../../../lib/referrals'
import { getOrgResultUrl } from '../../../../lib/routes'
import { Link } from 'react-router-dom'
import { Button } from '../../../form/button/Button'
import { resendVerificationEmail } from '../../../../lib/user'

export function OrgReferrals() {
  const [referrals, setReferrals] = useState<Referral[]>([])
  const { org, theme } = useOrganisationContext()
  const [loading, setLoading] = useState(true)
  const [profile, setProfile] = useState('')
  const [tableData, setTableData] = useState<BasicTableProps | undefined>()
  const [sendingEmail, setSendingEmail] = useState(false)
  const user = useUser()

  useEffect(() => {
    // set the profile to be the first one
    if (user) {
      setProfile(user?.profileUuid)
    }
    if (profile && org) {
      getTestsData(profile, org.uuid).then((theReferrals) => {
        setReferrals(theReferrals)
        setLoading(false)
      })
    }
    // set default profile to be the first one

    // fetch the tests here
  }, [user, profile, org])

  useEffect(() => {
    // set the profile to be the first one
    // console.log("we are here, the tests should have updated", results);
    if (referrals) {
      massageTestDataIntoTable(referrals)
    }
    // set default profile to be the first one

    // fetch the tests here
  }, [referrals])

  const massageTestDataIntoTable = (referrals: Referral[]) => {
    const result = []
    const resultsByYear: Record<string, BasicTableGroupProps> = {}
    // console.log("referrals", referrals);
    for (const i in referrals) {
      const d = new Date(referrals[i].createdAt)
      const year = d.getFullYear()

      // if (year !== currentYear) {
      if (!resultsByYear[year]) {
        resultsByYear[year] = {
          groupLabel: year.toString(),
          rows: [],
        }
      }
      // console.log("files", referrals[i].fileUuids);
      const mainFile = referrals[i].fileUuids?.pop()
      resultsByYear[year].rows.push([
        referrals[i].customerName || '',
        `${referrals[i].testName} referral`,
        `${getNiceDate(d.getDate())} ${
          months[d.getMonth()]
        } ${d.getFullYear()}`,
        referrals[i].reference,
        referrals[i].resultUuid !== null && org ? (
          <Link
            className="hover:text-bgt-primary"
            to={`${getOrgResultUrl(org.id, referrals[i].resultUuid!)}`}
          >
            <Button
              color={referrals[i].status === 'COMPLETE' ? `jade` : 'mandarin'}
              type="button"
              label={
                referrals[i].status === 'COMPLETE'
                  ? `View Results`
                  : 'View Partial Results'
              }
            ></Button>
          </Link>
        ) : referrals[i]?.status !== 'PENDING_VERIFICATION' && mainFile ? (
          <button
            className="cursor-pointer text-blood hover:underline"
            title="Download Referral"
            onClick={() =>
              downloadFile({
                user: user || undefined,
                fileUuid: mainFile,
                organisationUuid: org?.uuid,
                profileUuid: profile,
              })
            }
          >
            Download Referral
          </button>
        ) : referrals[i]?.status === 'PENDING_VERIFICATION' ? (
          <Button
            label="Resend email verification"
            onClick={async () => {
              setSendingEmail(true)
              await resendVerificationEmail({
                profileUuid: referrals[i].profileUuid!, // we can assume we have a profileUuid
                organisationUuid: referrals[i].organisationUuid!, // we will have an organisation uuid for org referrals
              })
              setSendingEmail(false)
            }}
            loading={sendingEmail}
          ></Button>
        ) : (
          'Awaiting results'
        ),
      ])
    }
    const keys = Object.keys(resultsByYear)
    keys.sort().reverse()
    for (const y in keys) {
      result.push(resultsByYear[keys[y]])
    }
    if (result.length > 0) {
      setTableData({
        columns: [
          'Customer Name',
          'Referral',
          'Date Purchased',
          'Reference',
          '',
        ],
        data: result,
      })
    }
  }

  useEffect(() => {
    setDocumentTitle('Client Referrals')
  }, [])

  const colClasses = [
    'text-left col-span-3 sm:col-span-2 border-b-1 border-gray-dark content-center pt-4  rounded-tl-lg rounded-bl-lg', //name
    'text-left col-span-3 sm:col-span-4 text-gray-semi-dark  content-center', // test name
    'text-left col-span-3 sm:col-span-2 sm:text-center text-gray-semi-dark  content-center', //reference
    'text-left col-span-3 sm:col-span-2 sm:text-left text-gray-semi-dark  content-center', //purchase date
    'text-left col-span-2 sm:col-span-2 sm:text-right text-gray-semi-dark  content-center pb-4 rounded-tr-lg rounded-br-lg', // status
    // "sm:text-right text-gray-semi-dark col-span-3 sm:col-span-3", // status
  ]

  return (
    <Fragment>
      {org && (
        <OrgPage
          theme={theme || undefined}
          org={org}
          tabs={[{ name: 'Referrals' }]}
        >
          {!loading && tableData && tableData.data && (
            <PageSection>
              <PageHeading title="Referrals"></PageHeading>

              <div
                key={`test-table`}
                className="grid grid-cols-3 sm:grid-cols-12 mt-10"
              >
                {/* <GridTableHeader className="col-span-3 sm:col-span-8">
                Pathology Report
              </GridTableHeader> */}
                <div
                  className={`bg-gray-light dark:shadow-lg dark:bg-dark-gray-light text-gray-semi-dark border-b border-gray-medium dark:border-dark-gray-light/50  font-semibold text-sm px-2 py-1 sm:p-3 sm:px-4 hidden sm:block ${colClasses[0]}`}
                >
                  {tableData.columns[0]}
                </div>
                <div
                  className={`bg-gray-light dark:shadow-lg dark:bg-dark-gray-light text-gray-semi-dark border-b border-gray-medium dark:border-dark-gray-light/50  font-semibold text-sm  px-2 py-1 sm:p-3 hidden sm:block ${colClasses[1]}`}
                >
                  {tableData.columns[1]}
                </div>
                <div
                  className={`bg-gray-light dark:shadow-lg dark:bg-dark-gray-light text-gray-semi-dark border-b border-gray-medium dark:border-dark-gray-light/50  font-semibold text-sm px-2 py-1 sm:p-3 hidden sm:block ${colClasses[2]}`}
                >
                  {tableData.columns[2]}
                </div>
                <div
                  className={`bg-gray-light dark:shadow-lg dark:bg-dark-gray-light text-gray-semi-dark border-b border-gray-medium dark:border-dark-gray-light/50  font-semibold text-sm  px-2 py-1 sm:p-3 hidden sm:block ${colClasses[3]}`}
                >
                  {tableData.columns[3]}
                </div>
                <div
                  className={`bg-gray-light dark:shadow-lg dark:bg-dark-gray-light text-gray-semi-dark border-b border-gray-medium dark:border-dark-gray-light/50 font-semibold text-sm  px-2 py-1 sm:p-3 hidden sm:block ${colClasses[4]}`}
                >
                  {tableData.columns[4]}
                </div>

                {tableData.data.map((group, g) => (
                  <Fragment key={`group-fragment-${g}`}>
                    {tableData.data.length > 1 && (
                      <div
                        key={`group-label-${g}`}
                        className={`px-2 py-1 sm:p-3 sm:px-4 col-span-3 sm:col-span-12 text-sm text-gray-semi-dark  bg-gray-light dark:bg-dark-gray-light font-bold dark:shadow-lg`}
                      >
                        {group.groupLabel}
                      </div>
                    )}
                    {group.rows.map((row, k) =>
                      row.map((item, i) => (
                        <div
                          key={`item-row-${k}-${i}`}
                          className={`py-1 sm:p-3 sm:px-4 col-span-1 sm:col-span-2 text-md text-black dark:text-white  ${
                            k % 2 === 1
                              ? 'bg-gray-light dark:bg-dark-gray-light'
                              : ''
                          } ${colClasses[i]} ${
                            i !== row.length - 1
                              ? ''
                              : 'last-row sm:border-none border-b border-gray '
                          } ${k === group.rows.length - 1 ? 'border-none' : ''}`}
                        >
                          <div className="inline-block align-middle">
                            {item}
                          </div>
                        </div>
                      ))
                    )}
                  </Fragment>
                ))}
              </div>
            </PageSection>
          )}
          {!loading && !tableData && (
            <EmptyState
              image={Clipboard}
              title="No client referrals found"
              alt="Clipboard Image"
            >
              Any referrals associated with your organisation will appear here
            </EmptyState>
          )}
          {loading && <PageLoader></PageLoader>}
        </OrgPage>
      )}
    </Fragment>
  )
}
