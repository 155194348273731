import { useState } from 'react'
import { setDocumentTitle } from '../../../lib/utils'
// import HL7ConnectorTab from '../../admin/hl7/HL7ConnectorTab'
// import StagingResultsTab from '../../admin/hl7/StagingResultsTab'
import TriageTab from '../../admin/hl7/TriageTab'
import { PageHeading } from '../../layout/PageHeading'
import AdminPage from './AdminPage'

const tabs = [
  { key: 'triage', title: 'Triage', component: <TriageTab /> },
  // {
  //   key: 'hl7connector',
  //   title: 'HL7 Connector',
  //   component: <HL7ConnectorTab />,
  // },
  // {
  //   key: 'stagingresult',
  //   title: 'Staging Results',
  //   component: <StagingResultsTab />,
  // },
]

export default function AdminHL7Page() {
  const [activeTab, setTab] = useState('triage')

  setDocumentTitle('HL7 Admin', 'CLEAN')

  const activeTabContent = tabs.find((tab) => tab.key === activeTab)?.component

  return (
    <AdminPage fullViewport={true}>
      <div
        className={`bg-white px-4 py-5 shadow sm:p-6 border border-gray-light dark:bg-neutral-800 dark:border-0 dark:shadow-xl dark:text-white`}
      >
        <PageHeading title="HL7 Admin" />
        {/* Tab Selector */}
        <div className="flex space-x-4 border-b border-gray dark:bg-dark-gray-lighter dark:border-0 dark:shadow-xl">
          {tabs.map(({ key, title }) => (
            <button
              key={key}
              onClick={() => setTab(key)}
              className={`px-4 py-2 ${
                activeTab === key ? 'border-b-2 border-blood text-blood' : ''
              }`}
            >
              {title}
            </button>
          ))}
        </div>
        {/* Render Content Based on Active Tab */}
        <div className="mt-4">
          {activeTabContent || <div>Tab not found.</div>}
        </div>
      </div>
    </AdminPage>
  )
}
