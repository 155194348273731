import { useMemo, useState } from 'react'
import { HL7TriageRequest, useHL7Triage } from '../../../lib/hl7'
import { useCurrentTheme } from '../../../lib/hooks'
import { StatusTagColors } from '../../../lib/interfaces'
import { HL7Triage } from '../../../lib/validators'
import SimpleSelectField from '../../form/SimpleSelectField'
import TextInputField from '../../form/TextInputField'
import { StatusTag } from '../../layout/StatusTag'
import MonacoEditor from '../../monaco/editor'

export default function TriageTab() {
  const [searchTerm, setSearchTerm] = useState('')
  const [filter, setFilter] = useState('')
  const [selectedItem, setSelectedItem] = useState<HL7Triage | null>(null)

  const listRequest = useMemo<HL7TriageRequest>(
    () => ({
      filter: {
        search: searchTerm || undefined,
        status: filter || undefined,
      },
      sort: {
        field: 'createdAt',
        order: 'DESC',
      },
      limit: 20,
      includeContent: false,
    }),
    [searchTerm, filter]
  )

  const { items, loading } = useHL7Triage(listRequest)

  return (
    <div className="flex flex-1 overflow-hidden">
      <LeftPanel
        items={items}
        loading={loading}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        filter={filter}
        setFilter={setFilter}
        onSelectedItem={setSelectedItem}
      />
      {selectedItem ? (
        <RightPanel uuid={selectedItem.uuid} />
      ) : (
        <div className="flex-1 p-4">
          <p>Select an Item</p>
        </div>
      )}
    </div>
  )
}

interface LeftPanelProps {
  items: HL7Triage[]
  loading: boolean
  searchTerm: string
  setSearchTerm: (value: string) => void
  filter: string
  setFilter: (value: string) => void
  onSelectedItem: (item: HL7Triage) => void
}

function LeftPanel({
  items,
  loading,
  searchTerm,
  setSearchTerm,
  filter,
  setFilter,
  onSelectedItem,
}: LeftPanelProps) {
  const statusColorMap: Record<string, string> = {
    COMPLETE: 'border-l-jade dark:border-l-jade',
    REVIEW: 'border-l-blood dark:border-l-blood',
    PENDING: 'border-l-mandarin dark:border-l-mandarin',
  }

  return (
    <div className="w-1/4 border-r border-gray-light dark:border-dark-gray-lighterer flex flex-col">
      <div className="p-4 border-b border-gray-light dark:border-dark-gray-lighterer flex flex-col gap-2">
        <TextInputField
          id="search"
          name="search"
          type="text"
          placeholder="Search..."
          value={searchTerm}
          handleChange={(e) => setSearchTerm(e.strValue)}
        />
        <SimpleSelectField
          name="filter"
          value={filter}
          handleChange={(e) => setFilter(e.strValue)}
          options={[
            { label: 'All', value: '' },
            { label: 'Pending', value: 'PENDING' },
            { label: 'Review', value: 'REVIEW' },
            { label: 'Complete', value: 'COMPLETE' },
          ]}
        />
      </div>
      {loading ? (
        <div className="p-4 text-center">Loading...</div>
      ) : (
        <div className="flex-1 overflow-y-auto">
          {items.map((item) => {
            const borderColor =
              statusColorMap[item.status] || 'border-l-gray dark:border-l-gray'

            return (
              <div
                key={item.uuid}
                className={`
                  p-2
                  border-b
                  border-gray-light 
                  dark:border-dark-gray-lighterer 
                  text-black/70 
                  dark:text-white/80 
                  hover:text-black 
                  hover:dark:text-white 
                  hover:bg-gray-light 
                  hover:dark:bg-dark-gray-light
                  border-l-4
                  cursor-pointer
                  ${borderColor}
                `}
                onClick={() => onSelectedItem(item)}
              >
                <div className="flex items-center justify-between w-full">
                  <div>{item.createdAt.toISOString().slice(0, 10)}</div>
                  <div>{item.uuid.slice(0, 8)}</div>
                </div>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

interface RightPanelProps {
  uuid: string
}

function RightPanel({ uuid }: RightPanelProps) {
  const theme = useCurrentTheme()
  const monacoTheme = theme === 'light' ? 'light' : 'vs-dark'

  const contentRequest = useMemo<HL7TriageRequest>(
    () => ({
      filter: { uuids: [uuid] },
      sort: {
        field: 'createdAt',
        order: 'DESC',
      },
      includeContent: true,
    }),
    [uuid]
  )

  const { items, loading } = useHL7Triage(contentRequest)
  const item = items[0] // Expect only one item

  const statusColorMap: Record<string, StatusTagColors> = {
    COMPLETE: StatusTagColors.JADE,
    REVIEW: StatusTagColors.BLOOD,
    PENDING: StatusTagColors.MANDARIN,
  }

  const downloadFile = (
    content: string | undefined | null,
    fileName: string
  ) => {
    if (!content) {
      console.error('No content to download')
      return
    }

    const blob = new Blob([content], { type: 'text/plain;charset=utf-8' })
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = fileName
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  if (loading) {
    return <div className="p-4">Loading content...</div>
  }

  if (!item) {
    return <div className="p-4">No content found for the selected item.</div>
  }

  const statusColor = statusColorMap[item.status] || StatusTagColors.GRAY

  return (
    <div className="flex-1 p-4 flex flex-col gap-6 overflow-hidden">
      {/* Triage Header Section */}
      <div className="space-y-2">
        <div className="flex items-center justify-between">
          <h2 className="text-2xl font-bold">Triage</h2>
          <StatusTag label={item.status} color={statusColor} />
        </div>
        <div className="space-y-1 text-sm font-mono">
          <div>Triage uuid: {item.uuid}</div>
          <div>Source uuid: {item.sourceUuid}</div>
          <div>Reference: {item.reference}</div>
          <div>Provider: {item.provider}</div>
        </div>
      </div>

      {/* Raw HL7 File Section */}
      <div className="flex-1 flex flex-col">
        <div className="flex justify-between items-center mb-3">
          <h3 className="text-lg font-semibold">Raw HL7 File</h3>
          {item.hl7Content && (
            <button
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              onClick={() =>
                downloadFile(
                  item.hl7Content,
                  item.hl7Key.replace(/\//g, '_').replace('_hl7.enc', '.hl7')
                )
              }
            >
              Download
            </button>
          )}
        </div>
        {item.hl7Content ? (
          <div className="flex-1 min-h-[400px] overflow-hidden">
            <MonacoEditor
              height="100%"
              language="plaintext"
              theme={monacoTheme}
              value={item.hl7Content}
              options={{
                readOnly: true,
                wordWrap: 'on',
                minimap: { enabled: false },
                renderWhitespace: 'all',
              }}
            />
          </div>
        ) : (
          <p className="text-gray-400 italic">No content available</p>
        )}
      </div>

      {/* Parsed JSON Section */}
      <div className="flex-1 flex flex-col">
        <div className="flex justify-between items-center mb-3">
          <h3 className="text-lg font-semibold">Parsed JSON</h3>
          {item.jsonContent && (
            <button
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              onClick={() =>
                downloadFile(
                  item.jsonContent,
                  item.jsonKey.replace(/\//g, '_').replace('_json.enc', '.json')
                )
              }
            >
              Download
            </button>
          )}
        </div>
        {item.jsonContent ? (
          <div className="flex-1 min-h-[400px] overflow-hidden">
            <MonacoEditor
              height="100%"
              language="json"
              theme={monacoTheme}
              value={JSON.stringify(JSON.parse(item.jsonContent), null, 2)}
              options={{
                readOnly: true,
                wordWrap: 'on',
                minimap: { enabled: false },
                renderWhitespace: 'all',
              }}
            />
          </div>
        ) : (
          <p className="text-gray-400 italic">No content available</p>
        )}
      </div>
    </div>
  )
}
